var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "update-probe-check-modal" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.cText.title,
                visible: _vm.isShowModal,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                center: ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.isShowModal = $event
                },
                close: _vm.onCancel
              }
            },
            [
              _c(
                "el-form",
                { attrs: { inline: false, size: "small" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "探头ID",
                        required: "",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "9",
                              disabled: !!(_vm.editItem && _vm.editItem.id)
                            },
                            model: {
                              value: _vm.probeId,
                              callback: function($$v) {
                                _vm.probeId = $$v
                              },
                              expression: "probeId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "质检结果(多选)",
                        required: "",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    _vm._l(_vm.tagList, function(item) {
                      return _c("el-checkbox", {
                        key: item.id,
                        attrs: { label: item.tagName },
                        on: { change: _vm.onchangeCheckbox },
                        model: {
                          value: item.selected,
                          callback: function($$v) {
                            _vm.$set(item, "selected", $$v)
                          },
                          expression: "item.selected"
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "责任方(单选)",
                        required: "",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 4 },
                          on: {
                            change: function($event) {
                              _vm.handlingMethod = 0
                            }
                          },
                          model: {
                            value: _vm.responsibleParty,
                            callback: function($$v) {
                              _vm.responsibleParty = $$v
                            },
                            expression: "responsibleParty"
                          }
                        },
                        [_vm._v("英沃")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 5 },
                          on: {
                            change: function($event) {
                              _vm.handlingMethod = 0
                            }
                          },
                          model: {
                            value: _vm.responsibleParty,
                            callback: function($$v) {
                              _vm.responsibleParty = $$v
                            },
                            expression: "responsibleParty"
                          }
                        },
                        [_vm._v("览宋")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "处理方式(单选)",
                        required: "",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.handlingMethod,
                            callback: function($$v) {
                              _vm.handlingMethod = $$v
                            },
                            expression: "handlingMethod"
                          }
                        },
                        [_vm._v("翻新/维护")]
                      ),
                      _vm.responsibleParty == 5
                        ? _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.handlingMethod,
                                callback: function($$v) {
                                  _vm.handlingMethod = $$v
                                },
                                expression: "handlingMethod"
                              }
                            },
                            [_vm._v("报废")]
                          )
                        : _vm._e(),
                      _vm.responsibleParty == 4
                        ? _c(
                            "el-radio",
                            {
                              attrs: { label: 3 },
                              model: {
                                value: _vm.handlingMethod,
                                callback: function($$v) {
                                  _vm.handlingMethod = $$v
                                },
                                expression: "handlingMethod"
                              }
                            },
                            [_vm._v("补新")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注(选填)",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: "4",
                          maxlength: "50",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.remark,
                          callback: function($$v) {
                            _vm.remark = $$v
                          },
                          expression: "remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loading },
                      on: { click: _vm.onCancel }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.type == 2 ? "danger" : "primary",
                        disabled: !_vm.isPass,
                        loading: _vm.loading
                      },
                      on: { click: _vm.addOrder }
                    },
                    [_vm._v(_vm._s(_vm.cText.btnText))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }