require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/dialog/style", "vant/es/dialog", "@/utils/str", "@/utils/time"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/dialog/style"), require("vant/es/dialog"), require("@/utils/str"), require("@/utils/time"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.dialog, global.str, global.time);
    global.updateProbeCheckModal = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _dialog, _str, _time) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _dialog = _interopRequireDefault(_dialog);
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  var _default = {
    name: "UpdateProbeCheckModal",
    data: function data() {
      return {
        loading: false,
        title: "",
        formLabelWidth: "120px",
        editItem: {},
        isShowModal: false,
        probeId: '',
        tagList: [],
        tags: [],
        responsibleParty: 0,
        handlingMethod: 0,
        remark: '',
        type: 0
      };
    },
    computed: {
      dialogWidth: function dialogWidth() {
        return window.screen.width >= 768 ? "50%" : "95%";
      },
      isPass: function isPass() {
        return (this.probeId.length == 8 || this.probeId.length == 9) && this.responsibleParty > 0 && this.handlingMethod > 0 && this.tags.length;
      },
      cText: function cText() {
        var title = '创建质检单';
        var btnText = '确认创建';

        if (this.editItem.id) {
          this.editItem.status == 0 && (title = '请填写质检记录');
          this.editItem.status != 0 && (title = '请确认最终原因');
          btnText = '确认并保存';
        }

        return {
          title: title,
          btnText: btnText
        };
      }
    },
    filters: {
      filterProbeOrderStatus: _str.filterProbeOrderStatus,
      getYMD: _time.getYMD
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      vm.name = name;
      vm.loadTagList();
    },
    methods: {
      // 新建质检单
      addOrder: function addOrder() {
        var vm = this;

        if (vm.editItem.id) {
          vm.onConfirm();
          return;
        }

        var msg = '责任方为“览宋”的订单，创建后将归到“待复核”，等待览宋确认';

        if (vm.responsibleParty == 4) {
          msg = "\u82F1\u6C83\u8D23\u4EFB\uFF0C".concat(vm.handlingMethod == 1 ? '翻新' : '补新', "\u7684\u63A2\u5934\uFF0C\u521B\u5EFA\u540E\u5C06\u5F52\u5230\u201C\u5DF2").concat(vm.handlingMethod == 1 ? '确认' : '完成', "\u201D\u4E0B\uFF0C\u7B49\u5F85").concat(vm.handlingMethod == 1 ? '翻新' : '补新');
        }

        _dialog.default.confirm({
          title: "提示",
          message: msg,
          confirmButtonColor: "#32BE32"
        }).then(function () {
          vm.onConfirm();
        }).catch(function () {});
      },
      // type: 2修改，1同意
      initData: function initData(item, type) {
        var vm = this;

        if (type == 1) {
          _dialog.default.confirm({
            title: "提示",
            message: "\u540C\u610F\u82F1\u6C83\u7684\u5224\u5B9A\u540E\uFF0C\u8BA2\u5355\u5C06\u8F6C\u4E3A\u201C".concat(item.status == 1 && item.handlingMethod == 1 ? '已确认' : '已完成', "\u201D"),
            confirmButtonColor: "#32BE32"
          }).then(function () {
            vm.onConfirm();
          }).catch(function () {});
        } else {
          vm.isShowModal = true;
        }

        vm.type = type;

        if (item.id) {
          item.tagIds && item.tagIds.length ? vm.tagList.map(function (tItem) {
            tItem.selected = item.tagIds.indexOf(tItem.id) > -1;
          }) : vm.tagList.map(function (tItem) {
            tItem.selected = false;
          });
          vm.editItem = item;
          vm.handlingMethod = item.handlingMethod;
          item.status && (vm.remark = item.remark);
          vm.responsibleParty = item.responsibleParty;
          vm.tags = [].concat(item.tagIds);
          vm.probeId = item.deviceId;
          return;
        } else {
          vm.tagList.map(function (tItem) {
            tItem.selected = false;
          });
          vm.probeId = '';
          vm.editItem = {};
        }

        vm.handlingMethod = 0;
        vm.remark = '';
        vm.responsibleParty = 0;
        vm.tags = [];
      },
      // 更新质检结果
      onchangeCheckbox: function onchangeCheckbox() {
        var arr = [];
        var vm = this;
        vm.tagList.map(function (item) {
          item.selected && arr.push(item.id);
        });
        vm.tags = arr;
        vm.tagList = [].concat(vm.tagList);
      },
      // 保存
      onConfirm: function onConfirm() {
        var vm = this;
        var url = 'careInfo/';

        if (vm.editItem.id) {
          url = vm.editItem.status == 0 ? "careInfo/".concat(vm.editItem.id) : 'careInfo/affirm';
        }

        vm.type == 1 && (url = 'careInfo/affirm');
        vm.ttid = _toast.default.loading({
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        var data = {
          password: vm.$config.base.password,
          operator: vm.name,
          probeId: vm.probeId,
          careInfoBaseRequest: {
            handlingMethod: vm.handlingMethod,
            remark: vm.remark,
            responsibleParty: vm.responsibleParty,
            tagIds: vm.tags
          }
        };
        vm.editItem.status && (data.careInfoId = vm.editItem.id);
        vm.type > 0 && (data.confirmStatus = vm.type);

        if (vm.type == 2) {
          vm.ttid.clear();
          var msg = '您未修改责任方和处理方式，是否确认继续？';

          if (vm.responsibleParty != vm.editItem.responsibleParty || vm.handlingMethod != vm.editItem.handlingMethod) {
            msg = vm.handlingMethod == 1 ? "\u4FEE\u6539\u8D23\u4EFB\u65B9\u548C\u5904\u7406\u65B9\u5F0F\u540E\uFF0C\u8BA2\u5355\u5C06\u8F6C\u4E3A\u201C\u5DF2".concat(vm.editItem.status == 1 && vm.handlingMethod == 1 ? '确认' : '完成', "\u201D") : "\u63A2\u5934\u5904\u7406\u65B9\u5F0F\u4E3A\u201C".concat(vm.handlingMethod == 3 ? '补新' : '报废', "\u201D\u7684\u8BA2\u5355\uFF0C\u5C06\u76F4\u63A5\u8F6C\u4E3A\u201C\u5DF2").concat(vm.editItem.status == 1 && vm.handlingMethod == 1 ? '确认' : '完成', "\u201D");
          }

          _dialog.default.confirm({
            title: "是否确认",
            message: msg,
            confirmButtonColor: vm.type == 2 ? '#F56C6C' : "#32BE32"
          }).then(function () {
            vm.updateAction({
              url: url,
              data: data
            });
          }).catch(function () {});

          setTimeout(function () {
            document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333;
            document.getElementsByClassName('van-overlay')[0].style.zIndex = 3332;
          }, 300);
          return;
        }

        vm.updateAction({
          url: url,
          data: data
        });
      },
      updateAction: function updateAction(params) {
        var vm = this;
        var url = params.url,
            data = params.data;
        vm.$http({
          type: vm.editItem.id ? 'PUT' : 'POST',
          url: "".concat(vm.$config.base.PROBE_BASE_URL).concat(url),
          data: data
        }).then(function (res) {
          vm.ttid.clear();

          if (res.code != 1) {
            return;
          }

          vm.isShowModal = false;
          vm.$emit('change');

          _toast.default.success({
            message: vm.editItem.id ? vm.type == 1 ? '操作成功' : '保存成功' : '创建成功'
          });
        }).catch(function () {
          vm.ttid.clear();
        });
      },
      closeModal: function closeModal() {
        this.onCancel();
      },
      // 获取质检标签列表
      loadTagList: function loadTagList() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/tags"),
          params: {
            type: 10
          }
        }).then(function (res) {
          vm.tagList = res.data || [];
        }).catch(function () {});
      },
      onCancel: function onCancel() {
        this.isShowModal = false;
      }
    }
  };
  _exports.default = _default;
});